import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import styled from 'styled-components';
import { Button, Box } from '@chakra-ui/react';
import HeroSection from '../components/heroSection';
import Proyectos from '../components/proyectos';
// import ilustracion from '../../content/images/servicios/404Illustration.svg';

// export const query = graphql`
//   query EquipoQuery {
//     headerImg: file(relativePath: { eq: "images/servicios/equipo.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1440, quality: 80) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//     headerImgMovil: file(relativePath: { eq: "images/servicios/equipo.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 600, quality: 80) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;

const Equipo = () => ( //{ data }
  <Layout>
    <Head pageTitle="Equipo profesional de producción audiovisual e ingenieria" />
    <Content>
      {/* <Flex alignContent="center" alignItems="center" flexDirection="column" overflow="hidden">
        <img
          src='/404Illustration.svg'
          style={{maxWidth: '90%',width: '650px', margin: '2em auto 1em'}}
          alt="404, No encontramos esa página"
        />
      </Flex> */}

      <HeroSection
        background={'transparent'}
        parrafoGrande={
          <Box textAlign='center'>
            Somos un equipo de profesionistas especializados en múltiples áreas
          </Box>
        }
        contentHeight="0"
        parrafoPequeno={
          <Box width='300px' maxWidth='90%' m='0 auto'>
            <ul>
              <li>Artes audiovisuales</li>
              <li>Ingenieria en sistemas</li>
              <li>Ingenieria mecatronica</li>
              <li>Animación y Modelado 3D</li>
              <li>Diseño grafico</li>
              <li>Diseño industrial</li>
              <li>Ilustración digital</li>
              <li>Artes plasticas</li>
              <li>Arquitectura</li>
            </ul>
            <br />
            <Button
              as="a"
              href="/contacto"
              colorScheme="cyan"
              p="1em 5em"
              maxWidth="90vw"
              m="0 auto"
              style={{ color: '#fff', marginTop: '14px' }}
            >
              Contactanos
            </Button>
          </Box>
        }
      />

      <Proyectos title="Nuestros proyectos" category="" limit={6} />
    </Content>
  </Layout>
);
Equipo.propTypes = {
  data: PropTypes.object.isRequired,
};
const Content = styled.div``;

export default Equipo;
